import {
  BACKGROUND_JOBS_ROUTE,
  ORGS_ROUTE,
  routeForIntegrationsIndex,
  routeForMarketplaceDevelopersList,
  routeForMarketplaceIndex,
  routeForMarketplaceProjectArchetypesList,
  routeForMarketplaceProjectOfferingsList,
  routeForMarketplaceProjectsList,
  routeForMarketplaceSuppliersList,
  routeForMarketplacePriceEstimatesList,
  routeForObjectViewer,
  routeForTcfdRisks,
  routeForTcfdOpportunities,
  urlForObject,
  urlForQueryPlayground,
  routeForFeatureFlags,
  routeForCompanies,
  routeForFinance,
  routeForCanonicalSchemas,
  routeForBusinessActivityTypes,
  routeForReportAnswerVerifier,
  routeForReportHealthChecks,
  routeForEmissionsModels,
  routeForMeasurementTests,
  routeForMarketplaceDocumentsList,
  routeForDuckHunt,
  routeForParquetViewer,
  routeForDescriptions,
  routeForReferenceData,
  routeForOrgSpecificMethodologyData,
  routeForGmailTools,
  routeForCanonicalDatasets,
  routeForSentEmails,
  routeForGlobalFootprintTagEditor,
  routeForUnitConverter,
  routeForLicensedCdpAnswers,
  routeForCanonicalProjects,
  routeForEmissionsModel,
  routeForFootprintTaggingEditor,
  adminLoginAsUrl,
  routeForReferenceDataSource,
  routeForReportQuestionAnswers,
  urlForActivityDataTable,
  routeForReportConfigs,
  routeForSupplyChainCharts,
  routeForReportQuestionMapping,
  routeForCdpIdMapping,
  adminLoginAsMyselfUrl,
  routeForMeasurementTestSuite,
  routeForReferenceDataCitations,
  routeForEngagementTasks,
  routeForCalculationTags,
  routeForCustomerTargetSchemas,
  routeForMethodologyExplorer,
  routeForStorybookInProduction,
  routeForIconsInStorybook,
  routeForEmissionsModelRelease,
  routeForEmissionsModelReleases,
  routeForFootprintDebugViewer,
  routeForCloudFileStorage,
  routeForImpactAssessment,
  routeForReportConfigObject,
  routeForSystemModelArchitecture,
  routeForTSchemaPlatform,
  routeForLifecycleAssessmentsForOrgId,
} from '@watershed/shared-universal/adminRoutes';
import flatten from 'lodash/flatten';
import {
  GQActiveWatershedEmployeeFieldsFragment,
  GQGetNonOrgSpecificQuickSwitcherDataQuery,
  GQGetOrgSpecificQuickSwitcherDataQueryVariables,
  GQGetOrgSpecificQuickSwitcherDataQuery,
  GQOrgFieldsForAdminNavFragment,
} from '@watershed/shared-universal/generated/graphql';
import formatOrgName from '@watershed/shared-universal/utils/formatOrgName';
import gql from 'graphql-tag';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { OperationResult } from 'urql';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import { PREFIX_SET } from '@watershed/shared-universal/generated/dbPrefixes';
import { previewDeployNames } from '@watershed/shared-universal/utils/helpers';
import { getCurrentDevEnv } from '@watershed/shared-frontend/utils/devEnv';

gql`
  query GetNonOrgSpecificQuickSwitcherData {
    emissionsModelsStable(showArchived: false) {
      id
      title
    }
    referenceDataSources(filter: All, excludeArchived: true, last: 1000) {
      edges {
        node {
          name
          id
          orgId
        }
      }
    }
    measurementTestSuites(includeDeleted: false, criticalOnly: false) {
      id
      title
    }
    reportConfigs {
      edges {
        node {
          id
          reportType
          shortName
          longName
          description
        }
      }
    }
    emissionsModelReleases {
      id
      businessActivityTypeName
      displayName
    }
  }
  query GetOrgSpecificQuickSwitcherData($orgId: ID!) {
    footprintTagsForOrg(orgId: $orgId) {
      id
      tagName
    }
    activityDataTables(orgId: $orgId, last: 1000) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

type GetOrgSpecificData = (
  orgId: string
) => Promise<
  OperationResult<
    GQGetOrgSpecificQuickSwitcherDataQuery,
    GQGetOrgSpecificQuickSwitcherDataQueryVariables
  >
>;

type Item = {
  title: string;
  /**
   * Either the route, or a function that creates a route with the search term passed in
   */
  url: string | ((searchTerm: string) => string);
  rank: number;
  additionalSearchTerms?: Array<string>;

  /**
   * This function can be used to create items that match a regex pattern
   * or match a few different input patterns without having to create a
   * bunch of additional search terms. The title of the item will _always_
   * be a default match, so no need to specify it here.
   */
  matchesSearchTerm?: (searchTerm: string) => boolean;

  /**
   * A tab on the parent will show these items
   */
  childItems?:
    | Array<Item>
    | ((getOrgSpecificData: GetOrgSpecificData) => Promise<Array<Item>>);

  /**
   * A title to interpolate into the "Jump to..." text for the sub menu
   */
  subMenuTitle?: string;

  // To determine whether to open the link in a new tab when clicked
  newTab?: boolean;
};

/**
 * Used in sorting, to slightly boost an item above another, for things like aliases matching in the wrong order
 */
const MINOR_RANK = 1;

/**
 * Used in sorting, to boost a set of items above others, for things like org-specific items above everything else
 */
const MAJOR_RANK = 100;

/**
 * Convenience function to apply org ranking to an item based on the current context
 */
function orgRanked(orgId: string | null, context: { orgId: string | null }) {
  return context.orgId === orgId ? MAJOR_RANK : -MAJOR_RANK;
}

/**
 * Returns a list of all quick switcher items, including ones that need to fetch more
 * data to properly populate.
 */
export function getQuickSwitcherItems(context: {
  orgs: Array<GQOrgFieldsForAdminNavFragment>;
  orgId: string | null;
  nonOrgSpecificQuickSwitcherData:
    | GQGetNonOrgSpecificQuickSwitcherDataQuery
    | undefined;
  activeWatershedEmployee: GQActiveWatershedEmployeeFieldsFragment;
}) {
  const refDataEntries =
    context.nonOrgSpecificQuickSwitcherData?.referenceDataSources?.edges
      .map((e) => e?.node)
      .filter(isNotNullish) ?? [];
  const accessibleOrgIds =
    context.activeWatershedEmployee.user.accessibleOrgs.map((org) => org.id);
  const items: Array<Item> = [
    {
      title: 'Organizations',
      url: ORGS_ROUTE,
      rank: 0,
    },
    {
      title: 'Marketplace',
      url: routeForMarketplaceIndex(),
      rank: 0,
    },
    {
      title: 'Marketplace: archetypes',
      url: routeForMarketplaceProjectArchetypesList(),
      rank: 0,
    },
    {
      title: 'Marketplace: suppliers',
      url: routeForMarketplaceSuppliersList(),
      rank: 0,
    },
    {
      title: 'Marketplace: projects',
      url: routeForMarketplaceProjectsList(),
      rank: 0,
    },
    {
      title: 'Marketplace: developers',
      url: routeForMarketplaceDevelopersList(),
      rank: 0,
    },
    {
      title: 'Marketplace: offerings',
      url: routeForMarketplaceProjectOfferingsList(),
      rank: 0,
    },
    {
      title: 'Marketplace: documents',
      url: routeForMarketplaceDocumentsList(),
      rank: 0,
    },
    {
      title: 'Marketplace: EAC price estimates',
      url: routeForMarketplacePriceEstimatesList(),
      rank: 0,
    },
    {
      title: 'Integrations',
      url: routeForIntegrationsIndex(),
      rank: 0,
    },
    {
      title: 'Calculation tags',
      url: routeForCalculationTags(),
      rank: 0,
    },
    {
      title: 'Report answers',
      url: routeForReportQuestionAnswers(),
      rank: 0,
    },
    {
      title: 'Emissions models',
      url: routeForEmissionsModels(),
      rank: MINOR_RANK, // above calculation method versions since "model" is more often this than a "version"
      childItems:
        context.nonOrgSpecificQuickSwitcherData?.emissionsModelsStable.map(
          (emissionsModel) => ({
            title: emissionsModel.title,
            url: routeForEmissionsModel(emissionsModel.id),
            rank: 0,
            additionalSearchTerms: [emissionsModel.id],
          })
        ),
      subMenuTitle: 'an emissions model',
    },
    {
      title: 'Measurement test suites (MTS)',
      url: routeForMeasurementTests(),
      rank: 0,
      childItems:
        context.nonOrgSpecificQuickSwitcherData?.measurementTestSuites.map(
          (testSuite) => ({
            title: testSuite.title,
            url: routeForMeasurementTestSuite(testSuite.id),
            rank: 0,
            additionalSearchTerms: [testSuite.id],
          })
        ),
      subMenuTitle: 'a measurement test suite',
    },
    {
      title: 'Calculation method versions (CMV)',
      url: routeForEmissionsModelReleases(),
      rank: 0,
      additionalSearchTerms: ['emissions model releases'],
      childItems:
        context.nonOrgSpecificQuickSwitcherData?.emissionsModelReleases.map(
          (emRelease) => ({
            title: `${emRelease.businessActivityTypeName} / ${emRelease.displayName}`,
            url: routeForEmissionsModelRelease(emRelease.id),
            rank: 0,
            additionalSearchTerms: [emRelease.id],
          })
        ),
      subMenuTitle: 'a calculation method version',
    },
    {
      title: 'Methodology explorer',
      url: routeForMethodologyExplorer(),
      rank: 0,
    },
    {
      title: 'Methodology user-visible descriptions',
      url: routeForDescriptions(),
      rank: 0,
    },
    {
      title: 'Workflows (aka Background jobs)',
      url: BACKGROUND_JOBS_ROUTE,
      rank: 0,
    },
    {
      title: 'Query playground',
      url: urlForQueryPlayground(),
      rank: 0,
    },
    {
      title: 'Footprint debug viewer',
      url: routeForFootprintDebugViewer(),
      rank: 0,
    },
    {
      title: 'Object viewer',
      url: (searchTerm) => routeForObjectViewer(searchTerm),
      rank: 0,
      // This one's special! It matches any db prefix so that you can paste an object id like
      // eme_a7sdfh37s and it'll take you to the object viewer when you hit enter.
      matchesSearchTerm: (input) => {
        // We need both a prefix and an id after the prefix to actually check if it's a valid db prefix
        if (!/[a-z]+_\w+/.test(input)) {
          return false;
        }
        const prefix = input.split('_', 1)[0];
        return PREFIX_SET.has(prefix);
      },
    },
    {
      title: 'Icons',
      url: routeForIconsInStorybook(),
      rank: 0,
    },
    {
      title: 'System Model (go/architecture)',
      url: routeForSystemModelArchitecture(),
      rank: 0,
    },
    {
      title: 'Companies',
      url: routeForCompanies(),
      rank: 0,
    },
    {
      title: 'Licensed CDP answers',
      url: routeForLicensedCdpAnswers(),
      rank: 0,
    },
    {
      title: 'Report question mapping',
      url: routeForReportQuestionMapping(),
      rank: 0,
    },
    {
      title: 'Impact Assessment Tooling',
      url: routeForImpactAssessment(),
      rank: 0,
    },
    {
      title: 'TSchema',
      url: routeForTSchemaPlatform(),
      rank: 0,
    },
    {
      title: 'CDP report identifier mapping',
      url: routeForCdpIdMapping(),
      rank: 0,
    },
    {
      title: 'Feature flags',
      url: routeForFeatureFlags(),
      rank: 0,
    },
    {
      title: 'Canonical schemas',
      url: routeForCanonicalSchemas(),
      rank: 0,
    },
    {
      title: 'Customer target schemas (CTS)',
      url: routeForCustomerTargetSchemas(),
      rank: 0,
    },
    {
      title: 'Duck hunt',
      url: routeForDuckHunt(),
      rank: -MAJOR_RANK,
    },
    {
      title: 'Parquet Viewer',
      url: routeForParquetViewer(),
      rank: 0,
    },
    {
      title: 'Reference Data',
      url: routeForReferenceData(),
      rank: 0,
      childItems: refDataEntries.map(({ id, name, orgId }) => ({
        title: name,
        url: routeForReferenceDataSource(id),
        additionalSearchTerms: [id],
        rank: orgRanked(orgId, context),
      })),
      subMenuTitle: 'reference data named',
    },
    {
      title: 'Reference data citations',
      url: routeForReferenceDataCitations(),
      rank: 0,
    },
    {
      title: 'Business activity types',
      url: routeForBusinessActivityTypes(),
      rank: 0,
    },
    {
      title: 'BAT schemas',
      url: routeForBusinessActivityTypes(),
      rank: 0,
      additionalSearchTerms: ['BART', 'BART schemas'],
    },
    {
      title: 'Report answer verifier failures',
      url: routeForReportAnswerVerifier(),
      rank: 0,
    },
    {
      title: 'Report footprint health checks',
      url: routeForReportHealthChecks(),
      rank: 0,
    },
    {
      title: 'Report configs',
      url: routeForReportConfigs(),
      rank: 0,
      subMenuTitle: 'a report config',
      childItems: context.nonOrgSpecificQuickSwitcherData?.reportConfigs.edges
        .map((e) => {
          if (!e?.node) {
            return null;
          }
          const { id, reportType, shortName, longName, description } = e.node;
          return {
            title: shortName,
            url: routeForReportConfigObject(e.node.id),
            rank: 0,
            additionalSearchTerms: [id, longName, description, reportType],
          };
        })
        .filter(isNotNullish),
    },
    {
      title: 'TCFD risk',
      url: routeForTcfdRisks(),
      rank: 0,
    },
    {
      title: 'TCFD opportunities',
      url: routeForTcfdOpportunities(),
      rank: 0,
    },
    {
      title: 'Gmail tools',
      url: routeForGmailTools(),
      rank: 0,
    },
    {
      title: 'Design system (Storybook)',
      url: routeForStorybookInProduction(),
      rank: 0,
      newTab: true,
    },
    {
      title: 'Canonical datasets and datasources',
      url: routeForCanonicalDatasets(),
      rank: 0,
    },
    {
      title: 'Sent emails',
      url: routeForSentEmails(),
      rank: 0,
    },
    {
      title: 'GraphQL Explorer',
      url: '/graphql',
      rank: 0,
    },
    {
      title: 'Default Tags',
      url: routeForGlobalFootprintTagEditor(),
      rank: 0,
    },
    {
      title: 'Unit converter',
      url: routeForUnitConverter(),
      rank: 0,
    },
    {
      title: 'Canonical projects',
      url: routeForCanonicalProjects(),
      rank: 0,
    },
    {
      title: 'Engagement tasks',
      url: routeForEngagementTasks(),
      rank: 0,
    },
    {
      title: 'Cloud file storage',
      url: routeForCloudFileStorage(),
      rank: 0,
    },
    ...flatten(
      context.orgs.map((org) => {
        const orgPrefix = `Org: ${formatOrgName(org)}`;
        const items: Array<Item> = [
          {
            title: `${orgPrefix}`,
            url: urlForObject('Organization', org.id),
            rank: 0,
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / footprint`,
            url: urlForObject('OrganizationFootprints', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
            childItems: async (getOrgSpecificData) => {
              const result = await getOrgSpecificData(org.id);
              const adts = flattenConnection(result?.data?.activityDataTables);
              return adts.map((adt) => ({
                title: stripOrgFromAdtName(adt.name),
                url: urlForActivityDataTable(org.id, adt.id),
                rank: orgRanked(org.id, context),
                additionalSearchTerms: [adt.id, org.id],
              }));
            },
          },
          {
            title: `${orgPrefix} / data review`,
            url: urlForObject('OrganizationDataReview', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / footprint tags`,
            url: urlForObject('OrganizationFootprints', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
            childItems: async (getOrgSpecificData) => {
              const result = await getOrgSpecificData(org.id);
              const tags = result?.data?.footprintTagsForOrg || [];
              return tags.map((tag) => ({
                title: tag.tagName,
                url: routeForFootprintTaggingEditor(org.id, tag.id),
                rank: orgRanked(org.id, context),
                additionalSearchTerms: [tag.id, org.id],
              }));
            },
            subMenuTitle: 'a tag',
          },
          {
            title: `${orgPrefix} / create footprint tag`,
            url: urlForObject('OrganizationCreateFootprintTag', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / datasets`,
            url: urlForObject('OrganizationDatasets', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / data issues`,
            url: urlForObject('OrganizationDataIssues', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / users`,
            url: urlForObject('OrganizationUsers', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / files`,
            url: urlForObject('OrganizationFiles', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / feature flags`,
            url: urlForObject('OrganizationFlags', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / finance`,
            url: routeForFinance(org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / summary report`,
            url: urlForObject('OrganizationEmployeeReport', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / emails`,
            url: urlForObject('OrganizationEmails', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / mappings`,
            url: urlForObject('OrganizationMappings', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / reports`,
            url: urlForObject('OrganizationReports', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / purchases`,
            url: routeForMarketplaceIndex({ orgId: org.id }),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / query playground`,
            url: urlForQueryPlayground({ orgId: org.id }),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / reduction plans`,
            url: urlForObject('OrganizationPlans', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / measurement context`,
            url: urlForObject('OrganizationMeasurementContext', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / company change requests`,
            url: urlForObject('OrganizationCompanyChangeRequests', org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / supply chain`,
            url: routeForSupplyChainCharts(org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / org-specific methodology data`,
            url: routeForOrgSpecificMethodologyData(org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / lifecycle assessment`,
            url: routeForLifecycleAssessmentsForOrgId(org.id),
            rank: orgRanked(org.id, context),
            additionalSearchTerms: [org.id],
          },
        ];

        const shouldRenderPreviewDeployOptions =
          getCurrentDevEnv() !== 'local-dev';
        // show Login As Myself for orgs that the authenticated user has access to
        if (accessibleOrgIds.includes(org.id)) {
          items.push(
            {
              title: `${orgPrefix} / login as ${context.activeWatershedEmployee.name}`,
              url: adminLoginAsMyselfUrl(org.id),
              rank: orgRanked(org.id, context),
              additionalSearchTerms: [org.id],
              newTab: true,
            },
            ...(shouldRenderPreviewDeployOptions
              ? previewDeployNames.map((name, idx) => ({
                  title: `${orgPrefix} / login as ${context.activeWatershedEmployee.name} - ${name}`,
                  url: adminLoginAsMyselfUrl(org.id, {
                    previewDeployName: name,
                  }),
                  rank: orgRanked(org.id, context) - (idx + 1),
                  additionalSearchTerms: [org.id],
                  newTab: true,
                }))
              : [])
          );
        }
        // show Login As for orgs that have any users, if they don't already have Login As Myself access
        if (!accessibleOrgIds.includes(org.id) && org.hasUsers) {
          items.push(
            {
              title: `${orgPrefix} / login as other user`,
              url: adminLoginAsUrl({ orgId: org.id }),
              rank: orgRanked(org.id, context),
              additionalSearchTerms: [org.id],
              newTab: true,
            },
            ...(shouldRenderPreviewDeployOptions
              ? previewDeployNames.map((name, idx) => ({
                  title: `${orgPrefix} / login as other user - ${name}`,
                  url: adminLoginAsUrl({
                    orgId: org.id,
                    previewDeployName: name,
                  }),
                  rank: orgRanked(org.id, context) - (idx + 1),
                  additionalSearchTerms: [org.id],
                  newTab: true,
                }))
              : [])
          );
        }
        return items;
      })
    ),
  ];
  return items;
}

function stripOrgFromAdtName(adtName: string): string {
  // More complicated because adtName.split(".", 2) does something different
  // than literally every other programming language I'm aware of
  const idx = adtName.indexOf('.');
  if (idx !== -1) {
    return adtName.substring(idx + 1);
  }
  return adtName;
}
