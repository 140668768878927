/** This file has been autogenerated. Do not edit **/

/**
 * This is a set of all DB prefixes, for use in places where we don't
 * have access to the db package itself
 */
export const PREFIX_SET = new Set<string>([
  'ade',
  'aduut',
  'adt',
  'adtd',
  'adtr',
  'apikey',
  'akr',
  'apireq',
  'apiupld',
  'appses',
  'apt',
  'apta',
  'aqdctfour',
  'acom',
  'aca',
  'acre',
  'fnh',
  'agp',
  'fnhr',
  'apmvi',
  'asb',
  'ay',
  'asmp',
  'apdesc',
  'aps',
  'aj',
  'bifm',
  'bisv',
  'bldg',
  'beo',
  'bldgh',
  'bls',
  'bldpr',
  'bldprh',
  'butil',
  'butilh',
  'butiluu',
  'bart',
  'bartvmv',
  'bat',
  'bate',
  'batf',
  'batv',
  'cadtsat',
  'ct',
  'ctems',
  'ctrds',
  'cdpreg',
  'cdpqim',
  'cdpse',
  'cdpswr',
  'cvd',
  'cpcal',
  'cpproj',
  'cpprojmeasproj',
  'cpprojdr',
  'cppstep',
  'cliqrdc',
  'com',
  'ccr',
  'ccc',
  'compset',
  'cdr',
  'cdrrd',
  'cdrrdo',
  'comptoref',
  'cdruut',
  'csat',
  'cvf',
  'mutev',
  'cdma',
  'cdmaq',
  'cdmat',
  'ctsfrm',
  'ctsfrmentry',
  'ctsvff',
  'ctsvffimp',
  'cefs',
  'cic',
  'cpd',
  'ctst',
  'ctstr',
  'ctstrt',
  'ctstrtr',
  'ctsv',
  'dae',
  'dsi',
  'dsim',
  'dsiuu',
  'dsetpar',
  'dset',
  'dsum',
  'dfpsfmd',
  'dplrfmd',
  'dtask',
  'dpr',
  'dscpfx',
  'dcit',
  'dlca',
  'dlcaat',
  'dtds',
  'dpat',
  'ems',
  'emsu',
  'ef',
  'efdesc',
  'emad',
  'emadpr',
  'eme',
  'emfork',
  'emfmatcher',
  'emm',
  'empr',
  'emrel',
  'emrelems',
  'emrelgftag',
  'emrellp',
  'emreloa',
  'emstable',
  'emv',
  'emvi',
  'emvrds',
  'emvrdv',
  'ere',
  'eyg',
  'efeedback',
  'emr',
  'engmtcohrt',
  'engmtcohrtmbr',
  'efun',
  'efunstp',
  'ennotset',
  'susu',
  'susua',
  'supatt',
  'engtaskbatch',
  'engtaskbatchlog',
  'susucom',
  'engtc',
  'engtcont',
  'engtq',
  'euere',
  'extbart',
  'exra',
  'exrqs',
  'exrer',
  'exrq',
  'exrsu',
  'exrt',
  'facs',
  'futa',
  'ff',
  'fto',
  'fsa',
  'fsdf',
  'fsf',
  'fsh',
  'fstt',
  'fsy',
  'fsef',
  'fset',
  'far',
  'fdd',
  'ffa',
  'ffah',
  'ffs',
  'fimp',
  'fsv',
  'fsett',
  'fst',
  'ft',
  'fw',
  'fwc',
  'frg',
  'fri',
  'foot',
  'fpar',
  'fab',
  'fbr',
  'fcru',
  'fcr',
  'fpd',
  'fpds',
  'feo',
  'fdru',
  'fdsu',
  'fpq',
  'frrq',
  'fsinit',
  'fps',
  'fpsrule',
  'fpsdr',
  'fpstag',
  'ftag',
  'fptscfcru',
  'fptscfdru',
  'fptscmai',
  'fptscftag',
  'fpv',
  'fvpin',
  'fcst',
  'fcscn',
  'fund',
  'fhb',
  'geoa',
  'gcc',
  'geor',
  'gftag',
  'gbl',
  'ik',
  'isbtia',
  'isbtic',
  'isbticu',
  'icf',
  'icfds',
  'icfdso',
  'qtyg',
  'icn',
  'inp',
  'idta',
  'idtr',
  'ioas',
  'irq',
  'lca',
  'log',
  'lat',
  'mkpai',
  'mkpair',
  'mkpajr',
  'mkpdc',
  'mkppu',
  'mkpem',
  'mkppuh',
  'mkppli',
  'mkpliem',
  'mkpplih',
  'mat',
  'matv',
  'ma',
  'madp',
  'mart',
  'mci',
  'mcih',
  'mcirh',
  'mcir',
  'mdmt',
  'mmap',
  'mmlog',
  'proj',
  'pdset',
  'mprojfpv',
  'pss',
  'mpti',
  'mpvmu',
  'asgn',
  'muta',
  'mvri',
  'mvrq',
  'nzp',
  'nzy',
  'osee',
  'ose',
  'ost',
  'orga',
  'orgcomp',
  'orgcompbf',
  'orgcompt',
  'odv',
  'opoc',
  'orgp',
  'osv',
  'osvmp',
  'osu',
  'otkm',
  'oun',
  'ouds',
  'our',
  'out',
  'outds',
  'outr',
  'oofm',
  'parq',
  'pat',
  'pcg',
  'pcgm',
  'perms',
  'plr',
  'plreme',
  'plrun',
  'plrunplr',
  'plan',
  'pga',
  'ptar',
  'playsql',
  'play',
  'pfo',
  'pfoa',
  'pfoi',
  'pce',
  'pabinit',
  'prid',
  'pw',
  'exr',
  'rdcit',
  'rdo',
  'rdr',
  'rdrc',
  'rdschm',
  'rds',
  'rdsql',
  'rdv',
  'rdvschm',
  'rpt',
  'repa',
  'repaver',
  'repaw',
  'repatt',
  'repai',
  'rep',
  'repv',
  'rptqry',
  'repq',
  'repqa',
  'repqc',
  'repqccu',
  'rqi',
  'rqim',
  'rdqi',
  'rnscpe',
  'rrv',
  'rrade',
  'role',
  'sandpkrl',
  'sandprltenr',
  'sbtt',
  'sbtiex',
  'shf',
  'shfr',
  'sts',
  'simpuo',
  'simp',
  'sims',
  'simsr',
  'simsreme',
  'seemym',
  'sepd',
  'simr',
  'sac',
  'sat',
  'saml',
  'supch',
  'supct',
  'supcd',
  'supmaprev',
  'supsv',
  'ss',
  'ssc',
  'suptc',
  'supps',
  'suppsfps',
  'stc',
  'sda',
  'trgt',
  'tioglt',
  'tiorgt',
  'trt',
  'trrn',
  'trrnt',
  'usr',
  'uir',
  'upr',
  'ur',
  'usrupld',
  'uuc',
  'uucr',
  'uucs',
  'task',
  'uut',
  'vmcom',
  'vmc',
  'valmaplm',
  'valmapt',
  'venmaplm',
  'vmt',
  'wsem',
  'fprevrq',
]);
